// eslint-disable-next-line import/prefer-default-export
export const websitePaymentsHero = {
  title: "Connect with customers with a better website",
  subtext:
    "Sell better in-store and online with a custom website and SpotOn payments. Whether you run a restaurant, retail location, or anything in between—our experts will build it for you.",
  heroImg: "websitePayments-pp-hero.png",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/website-payments/demo",
  },
  fatTitle: "Website",
};

export const websitePaymentsWhiteGlove = {
  mainTitle: "Let our experts build it for you",
  title: "Get a custom website build & lifetime support",
  content:
    "It takes 40 hours on average to build your own business website. Adding an ecommerce store can take even longer, not to mention the nightmare of trying to make it all work together. With SpotOn, you get one trusted technology partner to do it all for you. Our experts will build you a stunning website and help you set up shop online to better connect with your customers. And we’ll continue to be there for you with lifetime support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/website-payments/demo",
  },
};

export const websitePaymentsLargeFeatures = {
  sectionTitle: "Get found online. Get paid",
  featureBlocks: [
    {
      blockTitle: "What you get with SpotOn",
      blockImg: "websitePayments-a.png",
      blockList: [
        "Custom website built just for you",
        "Built-in payment processing",
        "Next-day funding",
        "Fair pricing with no cancellation or junk fees",
        "Online dashboard to easily manage everything",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/website-payments/demo",
      },
    },
    {
      blockTitle: "Commission-free online ordering for restaurants",
      blockImg: "websitePayments-b.png",
      blockList: [
        "Online ordering menu build",
        "SEO optimization to standout from the local competition",
        "Order with Google to attract more hungry guests",
        "POS integration options to streamline operations",
        "Automated mailing list builder",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/website-payments/demo",
      },
    },
    {
      blockTitle: "Reach new customers with ecommerce for retail",
      blockList: [
        "Custom ecommerce store—for both products and services",
        "Integrated stock management for your in-person and online shop",
        "Convenient options for local and national shipping",
        "Quick and easy shopping experience for customers",
        "Automated mailing list builder",
      ],
      blockImg: "websitePayments-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/website-payments/demo",
      },
    },
  ],
};

export const websitePaymentsTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const websitePaymentsCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/website-payments/demo",
  },
};

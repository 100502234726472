import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import { dynamicHeadlines } from "../../../utils/url-utils";
import Hero from "../../../components/Hero/hero";
import {
  websitePaymentsHero,
  websitePaymentsWhiteGlove,
  websitePaymentsLargeFeatures,
  websitePaymentsCTA,
  websitePaymentsTestimonials,
} from "../../../data/product-pages/website-payments-data";
import heroBg from "../../../images/hero-images/home-decor-hero.png";

// const NoVisualsCustom = loadable(() =>
//   import("../../../components/NoVisuals/NoVisualsCustom")
// );
const Badges = loadable(() =>
  import("../../../components/ConversionPath/Badges")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);

const whiteGloveImport = "websitePayments-whiteglove.png";

const WebsitePayments = () => {
  return (
    <Layout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Website and Payments | Website and Payments Software | SpotOn"
        description="Streamline operations, connect with patients online, and increase revenue at your dental practice with SpotOn. Our integrated technology is powerful yet easy to use—and it’s backed by local service and 24/7 technical support."
        image={`https://spoton.com/${heroBg}`}
      />
      <Hero
        sectionData={websitePaymentsHero}
        heroBg="websitePayments-decor-hero.png"
        dynamicH1={dynamicHeadlines()}
      />
      <section style={{ marginTop: "40px" }}>
        <Badges />
      </section>
      <section style={{ marginTop: "40px" }}>
        <TestmonialReviews sectionData={websitePaymentsTestimonials} />
      </section>
      <PartnerLogos />
      <LargeFeatures
        sectionData={websitePaymentsLargeFeatures}
        style={{ marginTop: "128px !important" }}
      />
      <WhiteGlove
        sectionData={websitePaymentsWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={websitePaymentsCTA} />
      <TestmonialReviews sectionData={websitePaymentsTestimonials} />
      {/* <Faqs sectionData={websitePaymentsFaqs} /> */}
    </Layout>
  );
};

export default WebsitePayments;
